import React from "react";
import { v4 as uuidv4 } from "uuid";

import { fetchGet } from "utils/ajax-util";
import { getColorPalette } from "utils";
import { COURSE_ENTITIES_ENDPOINT_V2, CATEGORY_ENDPOINT } from "constants/endpoints";
import { PAGE_LOADED, PAGE_SCROLLED } from "constants/mixpanel";
import { Mixpanel } from "Mixpanel";
import { categoryConfigs } from "./duck/constants";

const Container = (Main) =>
  class CategoryFilteredCourses extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        courseList: [],
        isLoading: true,
        categoryInfo: {},
        colorSchema: null,
        page: 0,
        hasMore: true,
        uniqueId: null,
      };
    }

    fetchCoursesPerCategory = (isInitialFetch) => {
      const { isCourseFetching } = this.state;

      if (isCourseFetching) return;

      this.setState({
        isCourseFetching: true,
      });

      const {
        match: { params: { category } },
        loggedProfileReducer: { authKey, branch },
      } = this.props;
      const { page } = this.state;

      const nextpage = page + 1;
      fetchGet(
        `${COURSE_ENTITIES_ENDPOINT_V2}fetch_category_based_courses/?category=${categoryConfigs[category].key}&page=${nextpage}&no_of_tile=9&branch_key=${branch?.key}&is_primary_category=false`,
        authKey
      )
        .then((response) => {
          const {
            success,
            data: { courses, has_more },
          } = response;
          if (success) {
            let { courseList, uniqueId } = this.state;
            const { match: { params: { category } } } = this.props;
            
            

            if (category === "continue_learning") {
              courses.forEach((course) => {
                course.info.user_progress = course.progress;
                course.info.is_independent_item = course.learn_item;
              });
            }
            else if (category === "recommended") {
              courses.forEach((course) => {
                course.source_category = 'recommended'
            });
          }

            courseList = [...courseList, ...courses];

            this.setState({
              courseList,
              isLoading: false,
              page: nextpage,
              hasMore: has_more,
              isCourseFetching: false,
            });

            const { categoryInfo } = this.state;

            if (!isInitialFetch) {
              this.callMixpanelEvent(PAGE_SCROLLED, {
                page_type: categoryInfo.key,
                page_subtype: categoryInfo.name,
                content_fetched_per_page: courses.length,
                scroll_depth: nextpage,
                end_of_page: !!!has_more,
                unique_session_id: uniqueId,
              });
            } else {
              this.callMixpanelEvent(PAGE_LOADED, {
                page_type: categoryInfo.key,
              });
            }
          } else {
            this.setState({
              isLoading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
          });
          console.log(error);
        });
    };

    callMixpanelEvent = (eventName, attributes) => {
      const {
        loggedProfileReducer: { userEmail, branch },
      } = this.props;

      Mixpanel.track(eventName, {
        ...attributes,
        email: userEmail,
        branch_key: branch?.key,
        branch_name: branch?.name
          ? branch.name
          : window.sessionStorage.getItem("branchName"),
      });
    };

    componentDidMount() {
      const { match: { params: { category } } } = this.props;
      const { colour_scheme } = categoryConfigs[category];

      this.setState({
        uniqueId: uuidv4(),
        courseList: [],
        categoryInfo: categoryConfigs[category],
        colorSchema: getColorPalette(colour_scheme)
      });

      this.fetchCoursesPerCategory(true);
    }

    render() {
      return (
        <Main
          {...this.state}
          {...this.props}
          handleCategoryClick={this.handleCategoryClick}
          fetchCoursesPerCategory={this.fetchCoursesPerCategory}
        />
      );
    }
  };

export default Container;
