import styled from "styled-components";

export const CategoryFilteredCourse = styled.div`
  padding-bottom: 42px;

  .site-wrapper {
    padding-bottom: 0;
  }
`;

export const CategoryHeader = styled.div`
  padding: 14px 0;
  margin-bottom: 48px;
  background-color: ${(props) =>
    props.theme.COLOR_PALETTE[`${props.colorSchema}`].PRIMARY};

  h2 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  span {
    width: 53px;
    height: 53px;
    border-radius: 50%;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme.COLOR.WHITE};
  }

  img {
    width: 40px;
    height: 40px;
  }

  @media screen and (max-width: 995px) {
    margin-bottom: 30px;

    span {
      width: 45px;
      height: 45px;
    }

    img {
      width: 33px;
      height: 33px;
    }
  }

  @media screen and (max-width: 767px) {
    span {
      width: 40px;
      height: 40px;
    }

    img {
      width: 30px;
      height: 30px;
    }
  }
`;

export const InfiniteScrollLoader = styled.div`
  .custom-circular-loader {
    border-top-color: ${(props) =>
      props.theme.COLOR_PALETTE[`${props.colorSchema}`].TERTIARY};
  }
`;
