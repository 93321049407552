import React from "react";

import { CircularLoaderContainer } from "./style";

const CircularLoader = () => {
  return (
    <CircularLoaderContainer>
      <span className="custom-circular-loader"></span>
    </CircularLoaderContainer>
  );
};

export default CircularLoader;
