import { ContinueCategoryIcon, RecommendationsCategoryIcon } from "images";

export const categoryConfigs = {
    recommended: {
        name: "Recommended For You",
        colour_scheme: "pink",
        icon_url: RecommendationsCategoryIcon,
        key: "Recommended for you",
        content_source: "recommended_for_you_page",
    },
    continue_learning: {
        name: "Continue Learning",
        colour_scheme: "orange",
        icon_url: ContinueCategoryIcon,
        key: "Continue Learning",
        content_source: "continue_learning",
    },
};
