import React from "react";
import { v4 as uuidv4 } from "uuid";

import { getAllRoles } from "utils";
import NonPrimaryCategoryCourses from "pages/AllCategories/NonPrimaryCategoryCourses";

const CustomerPitchPage = React.lazy(() =>
  import("pages/customerPitch/customerPitchDetails")
);
const AnswersPage = React.lazy(() => import("pages/qa/answers"));
const QuestionsPage = React.lazy(() => import("pages/qa/questions"));
const FeedbackPage = React.lazy(() => import("pages/feedback"));
const QABase = React.lazy(() => import("components/QA/qaBase"));
const CoursePage = React.lazy(() => import("pages/course/course"));
const Webinars = React.lazy(() => import("pages/Webinar/WebinarListing"));
const WebinarDetailPage = React.lazy(() => import("pages/Webinar/WebinarDetails"));
const SearchPage = React.lazy(() => import("pages/search"));
const ContentDetailPage = React.lazy(() => import("pages/contentDetailPage"));
const MobileSearchPage = React.lazy(() => import("pages/mobileSearchPage"));
const Flashcards = React.lazy(() => import("pages/flashcards/flashcard"));
const Channel = React.lazy(() => import("pages/channel/channel"));
const ChannelList = React.lazy(() => import("pages/channel/channelList"));
const CourseListPage = React.lazy(() => import("pages/courseList"));
const IndependentContentListPage = React.lazy(() =>
  import("pages/independentContentList")
);
const LearningPathCoursePage = React.lazy(() =>
  import("pages/learningPathCourse")
);
const AssessmentPage = React.lazy(() => import("pages/Assessment/"));
const LearningPathListingPage = React.lazy(() =>
  import("pages/learningPath/learningPathListing")
);
const LearningPathLandingPage = React.lazy(() =>
  import("pages/learningPath/landingPage")
);
const AllCertificates = React.lazy(() => import("pages/AllCertificates/"));

const HybridLearningPath = React.lazy(() =>
  import("pages/hybridLearningPath/components")
);
const DynamicContentAccess = React.lazy(() => import('pages/DynamicContentAccess/index'));

const PremiumCoursePage = React.lazy(() =>
  import("pages/PremiumCourse")
);

const PaymentStatusPage = React.lazy(() => 
  import("components/PaymentStatusScreen")
)

const TurtleReels = React.lazy(() => import("pages/TurtleReels"));
const CategoryListing = React.lazy(() => import("pages/AllCategories/CategoryListing"));
const CategoryFilteredCourses = React.lazy(() => import("pages/AllCategories/CategoryFilteredCourses"));

const PremiumListingPage = React.lazy(() => import("pages/PremiumCourseListing"));
const PurchasedCoursesListing = React.lazy(() => import("pages/PremiumCourseListing/components/PurchasedCourseListing"))
const PartnerStoryDetailPage = React.lazy(() => import("pages/PartnerStories"))

const NewHomePage = React.lazy(() => import("pages/NewHomePage"));
const UserLearningPathListingPage = React.lazy(() => import("pages/UserLpListing"));
const DailyQuiz = React.lazy(() => import("pages/QuizCenter/DailyQuiz"));
const GyanIn2MinListing = React.lazy(() => import("pages/GyanIn2minListing"));
const QuizHomePage = React.lazy(() => import("pages/QuizCenter/QuizHomePage"));
const CategoryQuizPage = React.lazy(() => import("pages/QuizCenter/CategoryQuiz"));

export default [
  {
    path: "/branches/:id/customerpitch/:customerPitch",
    Component: CustomerPitchPage,
    id: uuidv4(),
    permission: [...getAllRoles],
  },
  {
    path: "/branches/:id/courses/:course/customerpitch/:customerPitch",
    Component: CustomerPitchPage,
    id: uuidv4(),
    permission: [...getAllRoles],
  },
  {
    path: "/branches/:id/courses/:course/qa/answers",
    Component: AnswersPage,
    id: uuidv4(),
    permission: [...getAllRoles],
  },
  {
    path: "/branches/:id/courses/:course/qa/questions",
    Component: QuestionsPage,
    id: uuidv4(),
    permission: [...getAllRoles],
  },
  {
    path: "/branches/:id/courses/:course/feedback",
    Component: FeedbackPage,
    id: uuidv4(),
    permission: [...getAllRoles],
  },
  {
    path: "/branches/:id/courses/:course/qa",
    Component: QABase,
    id: uuidv4(),
    permission: [...getAllRoles],
  },
  {
    path: "/branches/:id/courses/:course",
    Component: CoursePage,
    id: uuidv4(),
    permission: [...getAllRoles],
  },
  {
    path: "/webinars",
    Component: Webinars,
    id: uuidv4(),
    permission: [...getAllRoles],
    fullWidth: true
  },
  {
    path: "/webinars/:id",
    Component: WebinarDetailPage,
    id: uuidv4(),
    permission: [...getAllRoles],
    fullWidth:true,
  },
  {
    path: "/search",
    Component: SearchPage,
    id: uuidv4(),
    permission: [...getAllRoles],
  },
  {
    path: "/branches/:id/content/:content",
    Component: ContentDetailPage,
    id: uuidv4(),
    permission: [...getAllRoles],
  },
  {
    path: "/mobilesearch",
    Component: MobileSearchPage,
    id: uuidv4(),
    permission: [...getAllRoles],
  },
  {
    path: "/branches/:id/flashcards",
    Component: Flashcards,
    id: uuidv4(),
    permission: [...getAllRoles],
  },
  {
    path: "/channels/:channelKey",
    Component: Channel,
    id: uuidv4(),
    permission: [...getAllRoles],
  },
  {
    path: "/channels/:channelKey/channel-category/:channelCategoryKey",
    Component: CourseListPage,
    id: uuidv4(),
    permission: [...getAllRoles],
  },
  {
    path: "/branches/:branchKey/channels",
    Component: ChannelList,
    id: uuidv4(),
    permission: [...getAllRoles],
  },
  {
    path: "/branches/:id/channels/:channelKey/flashcards/",
    Component: Flashcards,
    id: uuidv4(),
    permission: [...getAllRoles],
  },
  {
    path: "/branches/:id/category/:category",
    Component: NonPrimaryCategoryCourses,
    id: uuidv4(),
    permission: [...getAllRoles],
    fullWidth: true,
  },
  {
    path: "/branches/:id/contents/:category",
    Component: IndependentContentListPage,
    id: uuidv4(),
    permission: [...getAllRoles],
  },
  {
    path: "/branches/:id/learningpath/:key/learn",
    Component: LearningPathCoursePage,
    id: uuidv4(),
    permission: [...getAllRoles],
  },
  {
    path: "/branches/:id/assessment/:assessmentKey",
    Component: AssessmentPage,
    id: uuidv4(),
    permission: [...getAllRoles],
  },
  {
    path: "/branches/:id/learningpath",
    Component: LearningPathListingPage,
    id: uuidv4(),
    permission: [...getAllRoles],
  },
  {
    path: "/branches/:id/learningpath/:learningPathKey",
    Component: LearningPathLandingPage,
    id: uuidv4(),
    permission: [...getAllRoles],
  },
  {
    path: "/branches/:id/learningpath/:learningPathKey/feedback",
    Component: FeedbackPage,
    id: uuidv4(),
    permission: [...getAllRoles],
  },
  {
    path: "/branches/:id/allcertificates",
    Component: AllCertificates,
    id: uuidv4(),
    permission: [...getAllRoles],
    fullWidth: true
  },
  {
    path: "/branches/:id/learning-path-begin",
    Component: HybridLearningPath,
    id: uuidv4(),
    permission: [...getAllRoles],
    fullWidth: true
  },
  {
    path: "/branches/:id/item/:contentType/category/:category",
    Component: DynamicContentAccess,
    id: uuidv4(),
    permission: [...getAllRoles],
  },
  {
    path: "/branches/:id/courses/:course/premium",
    Component: PremiumCoursePage,
    id: uuidv4(),
    permission: [...getAllRoles],
    fullWidth: true
  },
  {
    path: "/courses/:id/payment-status",
    Component: PaymentStatusPage,
    id: uuidv4(),
    permission: [...getAllRoles],
  },
  {
    path: "/branches/:id/turtle-reels",
    Component: TurtleReels,
    id: uuidv4(),
    permission: [...getAllRoles],
    fullWidth: true
  },
  {
    path: "/all-categories",
    Component: CategoryListing,
    id: uuidv4(),
    permission: [...getAllRoles],
  },
  {
    path: "/course-category/:categoryId",
    Component: CategoryFilteredCourses,
    id: uuidv4(),
    permission: [...getAllRoles],
    fullWidth: true
  },
  {
    path: "/premium-courses-listing",
    Component: PremiumListingPage,
    id: uuidv4(),
    permission: [...getAllRoles],
    fullWidth: true
  },
  {
    path: "/purchased-courses-listing",
    Component: PurchasedCoursesListing,
    id: uuidv4(),
    permission: [...getAllRoles],
    fullWidth: true
  },
  {
    path: "/partner-stories/:partnerStoryId/",
    Component: PartnerStoryDetailPage,
    id: uuidv4(),
    permission: [...getAllRoles],
  },
  {
    path: "/new-home-page",
    Component: NewHomePage,
    id: uuidv4(),
    permission: [...getAllRoles],
    fullWidth: true
  },
  {
    path: "/learningpath-listing",
    Component: UserLearningPathListingPage,
    id: uuidv4(),
    permission: [...getAllRoles],
    fullWidth: true
  },
  {
    path: "/contents/gyan_in_2_mins",
    Component: GyanIn2MinListing,
    id: uuidv4(),
    permission: [...getAllRoles],
    fullWidth: true
  },
  {
    path: "/contents/gyan_in_2_mins/category/:key",
    Component: GyanIn2MinListing,
    id: uuidv4(),
    permission: [...getAllRoles],
    fullWidth: true
  },
  {
    path: "/daily-quiz",
    Component: DailyQuiz,
    id: uuidv4(),
    permission: [...getAllRoles],
    fullWidth: true
  },
  {
    path: "/quiz-center",
    Component: QuizHomePage,
    id: uuidv4(),
    permission: [...getAllRoles],
    fullWidth: true
  },
  {
    path: "/category-quiz/:categoryKey",
    Component: CategoryQuizPage,
    id: uuidv4(),
    permission: [...getAllRoles],
    fullWidth: true
  }
];
