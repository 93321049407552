import React, { useEffect, useState } from "react";
import { getContentDeeplink } from "constants/deeplink";

import { CONTENT_TILE_CLICKED, HOME_PAGE_CONTENT_TILE_CLICKED } from "constants/mixpanel";

const Container = (Main) =>
    function Container(props) {
        const { contentClickMixpanelEvent, history, loggedProfileReducer, categoryFilteredCoursePage } = props;
        const [isMobile, setIsMobile] = useState(false);
        const onContentClick = (content) => {
            const { branch } = loggedProfileReducer;
            const { key, name, type, primary_category_info, skill_info } = content;

            let mixpanelContentObject = {
                content_name: name,
                content_type: type,
                content_key: key,
                category: "Gyan in 2 Mins",
                content_source: categoryFilteredCoursePage ? 'primary_category_listing_page' : 'homepage',
                deeplink: getContentDeeplink(branch.key) + key,
                primary_category: primary_category_info?.name,
                skill_info: skill_info
            }

            contentClickMixpanelEvent(categoryFilteredCoursePage ? CONTENT_TILE_CLICKED : HOME_PAGE_CONTENT_TILE_CLICKED, mixpanelContentObject);

            history.push(`/branches/${branch.key}/content/${key}`);
        };

        const checkWindowWidth = () => {
            if (window.innerWidth < 768 && !isMobile) {
                setIsMobile(true);
            } else if (window.innerWidth > 768 && isMobile) {
                setIsMobile(false);
            }
        };

        const handleEditClick = (e, contentKey) => {
            e.stopPropagation();
            const { branch } = loggedProfileReducer;
            history.push(`/branches/${branch.key}/editcontent/${contentKey}/`);
        };

        useEffect(() => {
            window.addEventListener("load", checkWindowWidth);
            window.addEventListener("resize", checkWindowWidth);

            return () => {
                window.removeEventListener("load", checkWindowWidth);
                window.removeEventListener("resize", checkWindowWidth);
            };
        }, []);

        useEffect(() => {
            checkWindowWidth();
        });

        const handlers = {
            handleEditClick: handleEditClick,
            onContentClick: onContentClick,
        };

        return <Main {...props} {...handlers} isMobile={isMobile} />;
    };

export default Container;
