import styled from "styled-components";

export const CircularLoaderContainer = styled.div`
  text-align: center;

  .custom-circular-loader {
    width: 30px;
    height: 30px;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #424242;
    border-radius: 50%;
    display: inline-block;
    animation: spin 1s linear infinite;
    -webkit-animation: spin 1s linear infinite;
  }

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
