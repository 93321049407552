import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import FullPageLoader from "components/FullPageLoader";
import NewCourseTile from "components/NewCourseTile";
import CircularLoader from "components/CircularLoader";

import {
  CategoryFilteredCourse,
  CategoryHeader,
  InfiniteScrollLoader,
} from "./style/style"; //order is change to avoid overriding of style by browser stylesheet

import "styles/reset.css";

import Container from "./container";

const CategoryFilteredCourses = (props) => {
  const {
    categoryInfo: { name, icon_url, content_source },
    isLoading,
    colorSchema,
    courseList,
    loggedProfileReducer,
    history,
    match,
    fetchCoursesPerCategory,
    hasMore
  } = props;

  return (
      <InfiniteScroll
        dataLength={courseList.length}
        next={fetchCoursesPerCategory}
        hasMore={hasMore}
        loader={
          isLoading || !courseList.length ? null : (
            <InfiniteScrollLoader colorSchema={colorSchema}>
              <CircularLoader />
            </InfiniteScrollLoader>
          )
        }
      >
        {isLoading ? (
          <FullPageLoader />
        ) : (
          <CategoryFilteredCourse>
            {colorSchema ? <CategoryHeader colorSchema={colorSchema}>
              <h2 className="category-title-heading-primary site-wrapper">
                <span>
                  <img src={icon_url} alt="Category Icon" />
                </span>
                {name}
              </h2> 
            </CategoryHeader>
            : null}
            <div className="grid-container-h3 site-wrapper">
              {Array.isArray(courseList) && courseList.length ? (
                courseList.map((courseInfo, index) => {
                  return (
                    <NewCourseTile
                      courseInfo={content_source === "continue_learning" ? courseInfo.info : courseInfo}
                      colorSchema={colorSchema}
                      loggedProfileReducer={loggedProfileReducer}
                      key={courseInfo.key}
                      history={history}
                      match={match}
                      content_source={content_source}
                      course_sequence={index + 1}
                    />
                  );
                })
              ) : (
                <div>No Course assigned to this category.</div>
              )}
            </div>
          </CategoryFilteredCourse>
        )}
      </InfiniteScroll>
    );
  };

export default Container(CategoryFilteredCourses);
